/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "./variables/variables";
@import "~flatpickr/dist/flatpickr.min.css";

.flatpickr-time {
  height: 40px !important;
}

/* Override our primary color in forms to reduce confusion with error state */
.form-control:not(.is-invalid):focus,
[dir]
  .input-group:not(.is-invalid):not(.bootstrap-touchspin):focus-within
  .form-control,
[dir]
  .input-group:not(.is-invalid):not(.bootstrap-touchspin):focus-within
  .input-group-text {
  border-color: #bdbdbd !important;
}

.input-group-text.info {
  padding: 0 !important;

  button {
    padding: 0.571rem 1rem !important;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent !important;
    }
  }
}

@import "./prcz/icons";

.apexcharts-legend-marker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 3px;
  border-style: solid;
}

.series-marker {
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 12px;

  &-repaid {
    background: rgb(164, 248, 205);
    color: rgb(164, 248, 205);
  }

  &-owed {
    background: #f9cfcf;
    color: #f9cfcf;
  }

  &-received {
    background: rgb(43, 218, 199);
    color: rgb(43, 218, 199);
  }
}

span.brand-logo img {
  max-width: 103px !important;
  transition: transform 0.6s ease;

  &.collapsed {
    transform: translateX(0.5rem) !important;
    transition: transform 0.6s ease;
  }
}

// scroll to Top Footer Button
.scroll-top {
  bottom: 2%;
}

.topup-modal {
  & .modal-dialog {
    max-width: 430px;

    & .modal-body {
      padding: 2.5rem;
    }
  }
}

.topup-modal-success {
  & .modal-dialog {
    & .modal-body {
      padding: 0;
      margin: 0;

      .card-topup-success {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.modal-header .btn-close {
  margin: 0.5rem 0.3rem -1rem auto !important;
  transform: translate(18px, -10px) !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.noUi-pips-horizontal {
  height: 0 !important;
}

// Congratulations Card
.card-topup-success {
  background: linear-gradient(118deg, rgba(#fd5858, 0.5), rgba(#fd5858, 0.75));
  color: white;

  .avatar {
    margin-bottom: 2rem;
  }
}

#ContractBreakdown {
  top: calc(50% - 20px);
  right: -18px;
  color: #fd5858;
  &:hover,
  &:focus {
    background-color: transparent !important;
  }
}

.min-height-238 {
  min-height: 238px;
}

.min-height-136 {
  min-height: 136px;
}

.navigation li a svg.lucide {
  align-self: baseline !important;
}

// /* ===== Scrollbar CSS ===== */
// /* Firefox */
// * {
//   scrollbar-width: thin;
//   scrollbar-color: #bdbdbd #ffffff;
// }

// /* Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 12px;
// }

// *::-webkit-scrollbar-track {
//   background: transparent;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: #fd5858aa;
//   border-radius: 10px;
//   border: 3px solid #ffffff;
// }

// body::-webkit-scrollbar-thumb {
//   border: 3px solid #f6f6f6;
// }

// .card-body::-webkit-scrollbar-thumb {
//   border: 3px solid #ffffff;
// }

// .dark-layout::-webkit-scrollbar-thumb {
//   border: 3px solid #161d31;
// }

// .dark-layout .card-body::-webkit-scrollbar-thumb {
//   border: 3px solid #283045;
// }

.zindex-2000 {
  z-index: 2000;
}

html .content.app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 2rem 0 2rem;
}

.header-navbar-shadow {
  transform: translateX(-12px);
}

.impersonated .header-navbar-shadow {
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 1) 44%,
    rgba(248, 248, 248, 1) 73%,
    rgba(255, 255, 255, 0)
  );
  width: 99%;
  transform: translate3d(-12px, 40.17px, 0px);
}

.timeline h6 {
  font-size: 0.9rem !important;
  font-weight: bolder !important;
  margin-bottom: 0;
}

.transaction-spinner {
  background-color: rgba(253, 88, 88, 0.12);
  width: 42px;
  height: 42px;
  margin-right: 1.2rem;

  .spinner-border-sm {
    border-width: 0.12em;
  }
}

.control-info .popover {
  max-width: 288px;
  .popover-header {
    background-color: $secondary;
    border-color: $secondary;
  }
}

.vcc-modal {
  .modal-content {
    border-radius: 25px;
    overflow: hidden;
  }
  .modal-body {
    padding: 0;
    margin: 0;
  }
}

#vccContainer {
  div {
    background: transparent;
    height: 315px;
  }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  visibility: visible;
}

.invalid-feedback {
  display: block;
  visibility: hidden;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.ps__thumb-y {
  background-color: #fd5858 !important;
}

.badge.badge-up.bank-connection-badge {
  right: -5px;
  top: -5px;
}

.badge.badge-up.integration-badge {
  right: 15px;
  top: 6px;
}

$rem-size: 1rem;

.bank-connection {
  min-height: 64px;
  width: 12 * $rem-size;
  max-height: 4 * $rem-size;
  height: auto;
}

.active-integration {
  min-height: 64px;
  min-width: 6 * $rem-size;
  max-width: 100%;
  max-height: 4 * $rem-size;
  height: auto;
}

.rounded-circle.inline {
  padding: 0.01rem !important;
  line-height: 0.25 !important;
  font-size: 4px !important;
}

.main-menu .navigation > li .active {
  background-color: #fd5858 !important;
  color: white !important;
  box-shadow: none;
  font-weight: 400;
  border-radius: 4px;
}

svg.waiting:not(.animated) .animable {
  opacity: 0;
}

svg.waiting.animated .back-screen {
  animation: 1.5s Infinite linear heartbeat;
  animation-delay: 0s;
}

svg.waiting.animated .character {
  animation: 1.5s Infinite linear floating;
  animation-delay: 0s;
}

svg.waiting.animated .lines {
  animation: 1.5s Infinite linear wind;
  animation-delay: 0s;
}

svg.waiting.animated .front-screens {
  animation: 1.5s Infinite linear heartbeat;
  animation-delay: 0s;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes wind {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  75% {
    transform: rotate(-1deg);
  }
}

.transactions-breakdown .timeline-event:after {
  content: "";
  display: block;
  border-bottom: 1px solid #ebe9f1;
  padding-top: 10px;
}

.fit-content {
  height: fit-content;
}

.cover-img {
  object-position: top;
  width: 300px;
  object-fit: cover;
}

.expandable-table-enter {
  opacity: 0.01;
}

.expandable-table-enter.expandable-table-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.expandable-table-leave {
  opacity: 1;
}

.expandable-table-leave.expandable-table-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.flatpickr-input[readonly] {
  background-color: #fff;
  opacity: 1;
}
